// export const API_BASE_URL = 'http://localhost:5000/api';

// export const API_BASE_URL = 'https://www.kernel360.kosmosuit.neurom.in/node-project/api';

// export const API_BASE_URL = 'https://www.kernel360.kosmosuit.com/kosmosuit/api';
// export const API_BASE_URL = 'https://dev.kernel360.kosmosuit.com/api';
// export const API_BASE_URL = 'http://20.62.198.211/api';
export const API_BASE_URL = 'https://kernel360.kosmosuit.com/api';


// export const API_BASE_URL = 'https://localhost:5000/api';

// export const API_BASE_URL = 'https://dev.greenvuetech.com/api';