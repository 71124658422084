import React, { useEffect, useContext, useState } from 'react';

import CryptoJS from 'crypto-js';

import { useNavigate } from 'react-router-dom';
import 'assets/css/header.css';
import { Link } from 'react-router-dom';
import { FrontHeaderDataContext } from 'contexts/FrontHeaderDataContext';
import Home from 'front/Home';
import { API_BASE_URL } from 'Config';
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  CardTitle,
  Table
} from "reactstrap";
import { API_ASSET_URL } from 'front/Simulation/common/config';
import PdfViewer from 'common/PdfViewer';
function Header(props) {
  const navigate = useNavigate();

  const [pdfModalSize, setPdfModalSize] = useState('lg'); // Default modal size
  const [pdfFile, setPdfFile] = useState(`${API_ASSET_URL}/assets/documents/Kosmosuit_18_Dec.pdf`); // Path to your PDF file
  const [visiblePages, setVisiblePages] = useState([9, 10, 11, 12, 13]);
  const [pdfModalShown, setPdfModalShown] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [modalShown, setModalShown] = React.useState(false);
  const [navbarName, setNavbarName] = React.useState("Session Setup");
  const { stageFlight, suitMode, activity, activitiesByFlightStage, changeStageFlight, changeSuitMode, changeActivity } = useContext(FrontHeaderDataContext);

  const handleStageFlightChange = (e) => {
    changeStageFlight(e.target.value);
  };

  function generateCustomId(user) {
    const firstInitial = user.first_name.charAt(0).toUpperCase();
    const lastInitial = user.last_name.charAt(0).toUpperCase();
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');

    // Assuming you want to use the first letter of the user's _id for the count
    // and just for demonstration, I'm using the numeric part of the _id for simplicity
    // In a real scenario, this should be a user count/sequence number specific to your application logic
    let userIdPart = user._id.replace(/[^\d]/g, '').slice(0, 3); // Extracts first 3 numeric characters from _id

    return `${firstInitial}${lastInitial}${year}${month}${userIdPart}`;
  }

  // Assuming you've already stored the user data in localStorage
  function retrieveAndGenerateId() {
    // Retrieve the user data string from localStorage
    const userDataString = localStorage.getItem('user');

    if (userDataString) {
      const user = JSON.parse(userDataString);
      const customId = generateCustomId(user);
      console.log(customId);
      return customId;
    } else {
      console.error('No user data found in localStorage');
      return null;
    }
  }

  // Example usage



  const customId = retrieveAndGenerateId();


  const handleSuitModeChange = (e) => {
    changeSuitMode(e.target.value);
  };

  const handleActivityChange = (e) => {
    changeActivity(e.target.value);
  };

  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  const togglePdfModal = () => {
    setPdfModalShown(!pdfModalShown);
  };
  const sessionModalSearch = () => {
    setModalShown(!modalShown);
  };

  const handleModalOpen = () => {
    const modalContentDiv = document.querySelector('.modal-content');
    if (modalContentDiv) {
      modalContentDiv.style.marginBottom = '20px';
      modalContentDiv.classList.add('card');

    }
  };

  // Fetch the PDF content from the API
  const fetchPdfFromApi = async () => {
    try {
      const response = await fetch(
        `${API_ASSET_URL}/assets/documents`
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch PDF. Status: ${response.status}`);
      }

      const data = await response.json();

      // Find the specific file (Kosmosuit_18_Dec.pdf)
      const pdfData = data.find((item) => item.filename === 'Kosmosuit_18_Dec.pdf');

      if (!pdfData || !pdfData.content) {
        throw new Error('PDF content not found in API response.');
      }

      // Update state with the Base64 content
      setPdfFile(pdfData.content); // The `content` is already Base64 with MIME type
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  useEffect(() => {
    // Fetch the PDF content when the component mounts
    fetchPdfFromApi();
  }, []);



  useEffect(() => {
    const updateModalSize = () => {
      const width = window.innerWidth;
      if (width <= 768) setPdfModalSize('sm'); // Small for mobile devices
      else if (width <= 1024) setPdfModalSize('md'); // Medium for tablets
      else if (width <= 1440) setPdfModalSize('lg'); // Large for desktops
      else setPdfModalSize('xl'); // Extra-large for larger screens
    };

    // Update size on component mount and window resize
    updateModalSize();
    window.addEventListener('resize', updateModalSize);

    return () => window.removeEventListener('resize', updateModalSize);
  }, []);

  useEffect(() => {

    const routeWithoutSlash = props.currentRoute.replace(/^\/|\/$/g, '');
    let pages = [];
    console.log(routeWithoutSlash)
    if (routeWithoutSlash === "admin/metrics") {
      sessionModalSearch();
      setTimeout(() => {
        setModalShown(false);
      }, 5000);

      // Clean up the timeout to avoid memory leaks
      // return () => clearTimeout(delay);
    }
    switch (routeWithoutSlash) {
      case 'session':
        pages = [8, 9, 10, 11, 12, 13]; // Show pages 13-16 for session route
        break;
      case 'home':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName('Session Id');
        break;
      case 'digital-fit':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName("Digital Fit");
        break;
      case 'simulate':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName("Simulate");
        break;
      case 'simulate/view':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName("Simulate");
        break;
      case 'simulate/analytics':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName("Simulate");
        break;
      case 'dashboard':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName("Dashboard");
        break;
      case 'suit':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName("Suite Library");
        break;
      case 'creat-suit':
        pages = [16, 17]; // Show pages 13-16 for session route
        setNavbarName("Create Suit");
        break;
      case 'simulate-state':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName("Simulate");
        break;
      case 'biomechanics':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName("Bio Mechanics");
        break;
      case 'summary':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName('Session Summary');
        break;
      case 'environment':
        pages = [13, 14, 15, 16]; // Show pages 13-16 for session route
        setNavbarName('Environment');
        break;
      // Add more cases as needed for different routes
      default:
        // Use the default name if the route doesn't match any specific case
        if (routeWithoutSlash.includes('admin')) {
          setNavbarName('Data Manager');
        } else {
          // Use the default name if the route doesn't match any specific case
          break;
        }
        break;
    }

    // setNavbarName(navbarName);
  }, [props.currentRoute]);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include the token in the headers for authentication
          'Authorization': `Bearer ${localStorage.getItem('token')}` // Include the token from localStorage
        }
      });

      if (response.ok) {
        console.log('Logout successful');
        // Optionally, clear token from localStorage or context/state
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiration');
        localStorage.removeItem('user');

        // Optionally, perform additional actions after logout
        // For example, navigate to the landing page after successful logout
        navigate('/');
      } else {
        const errorData = await response.json();
        console.error('Logout failed', errorData);
        // Handle errors (show to user, log, etc.)
      }
    } catch (error) {
      console.error('Logout request failed', error);
      // Handle network errors, show user feedback
    }
  };

  const handleMouseEnter = () => {
    setIsTooltipVisible(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipVisible(false);
  };

  return (
    <div style={{ background: '#14181B' }}>
      <header className="app-header">
        <div className="menu-heading">
          <Link to="/" aria-label="Home" className=" home-logo menu-item" alt="home" style={{ background: '#14181B', borderTop: '1px solid #14181B' }}>
            <img src={`${API_ASSET_URL}/assets/images/home.svg`} />
          </Link>
          <div className="vl" />
          <img src={`${API_ASSET_URL}/assets/images/logo.svg`} className="logo menu-item" alt="mrl logo" />
          <div className="vl" />
          <div id="title-portal" className="title text-white">
            {/* {navbarName} */}
            {customId}
          </div>
        </div>
        <div className="selector-container" style={{ display: navbarName === "Session Setup" ? 'none' : '' }}>
          <div className="selector text-white">
            <select disabled={true} className='text-white' name="stageFlight" value={stageFlight} onChange={handleStageFlightChange}>
              <option value="Pre Flight" className="options text-white">Pre Flight</option>
              <option value="In Flight" className="options text-white">In Flight</option>
              <option value="Post Flight" className="options text-white">Post Flight</option>
            </select>
            <div className="selector-label">
              <label>{stageFlight}</label>
              <img src={`${API_ASSET_URL}/assets/images/arrow.svg`} />
            </div>
          </div>
          <div className="selector text-white">
            <select disabled={true} className='text-white' name="suitMode" value={suitMode} onChange={handleSuitModeChange}>
              <option value="Virtual Suit" className="options text-white">Virtual Suit</option>
              <option value="Real Suit" className="options text-white">Real Suit</option>
            </select>
            <div className="selector-label">
              <label>{suitMode}</label>
              <img src={`${API_ASSET_URL}/assets/images/arrow.svg`} />
            </div>
          </div>
          <div className="selector text-white">
            <select disabled={true} className='text-white' name="activity" value={activity} onChange={handleActivityChange}>
              {activitiesByFlightStage[stageFlight].map((activityOption, index) => (
                <option key={index} value={activityOption} className="options text-white">{activityOption}</option>
              ))}
            </select>
            <div className="selector-label">
              <React.Fragment>
                <label onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  {activity.length <= 10 ? activity : `${activity.slice(0, 20)}...`}
                </label>
                {isTooltipVisible && (
                  <div className="tooltip">
                    {activity}
                  </div>
                )}
              </React.Fragment>
              <img src={`${API_ASSET_URL}/assets/images/arrow.svg`} />
            </div>
          </div>

        </div>
        <Button color="link" onClick={toggleModalSearch} style={{ display: navbarName === "Session Setup" ? 'none' : '' }}>
          <i className="text-white h4 fa fa-search" />
          <span className="d-lg-none d-md-block">Search</span>
        </Button>
        <Button color="link" className='text-white' onClick={togglePdfModal} style={{ position: 'absolute', top: '10px', right: '7%' }}>
          <i className="fa fa-eye text-white" />
        </Button>
        <Button color="link" className='text-white' onClick={handleLogout} style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <i className="fa fa-sign-out-alt text-white" />Logout {/* Logout icon */}
        </Button>
        <div />
      </header>

      <Modal
        modalClassName="modal-search"
        isOpen={modalShown}
        toggle={sessionModalSearch}
        onOpened={handleModalOpen}
      >
        <ModalHeader>
          <Row className="ml-5" style={{ fontFamily: 'Alternox-ExLight' }}>
            <Col className="col-3" style={{ textAlign: 'justify', whiteSpace: 'nowrap' }}>
              <p>Date: {new Date().toLocaleDateString()}</p>
            </Col>
            <Col className="col-3" >
              <p>Suit ID: KS 001</p>
            </Col>
            <Col className="col-3" >
              <p>Session ID: KS 001 aced1567</p>
            </Col>
            <Col className="col-3" >
              <p>Name: John Doe</p>
            </Col>
            <Col className="col-3" >
              <p>Age: 26</p>
            </Col>
          </Row>
          <Row className="ml-5" style={{ fontFamily: 'Alternox-ExLight' }}>
            <Col className="col-3" >
              <p>Gender: Male</p>
            </Col>
            <Col className="col-3" >
              <p>Ethnicity: Asian</p>
            </Col>
            <Col className="col-3" >
              <p>Stage: Preflight</p>
            </Col>
            <Col className="col-3" >
              <p>Vessel: NASA</p>
            </Col>
            <Col className="col-3" >
              <p>Location: GPSEnabled</p>
            </Col>
          </Row>
          <button
            aria-label="Close"
            className="close"
            onClick={sessionModalSearch}
          >
            <i className="fa fa-close" />
          </button>
          {/* <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="fa fa-close" />
          </button> */}

        </ModalHeader>
      </Modal>

      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
        onOpened={handleModalOpen}
      >
        <ModalHeader>
          <Input  placeholder="SEARCH"
            style={{
              color: 'white',
              backgroundColor: 'black',
              borderColor: 'white',
              opacity: 0.8,
            }}
            type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="fa fa-remove" />
          </button>
        </ModalHeader>
      </Modal>

      <Modal isOpen={pdfModalShown} toggle={togglePdfModal} size={pdfModalSize} className='card'>
        {/* <ModalHeader toggle={togglePdfModal}>PDF Viewer</ModalHeader> */}
        <div style={{ padding: '20px' }}>
          <PdfViewer file={pdfFile} visiblePages={visiblePages} />
        </div>
      </Modal>
    </div>


  );
}

export default Header;