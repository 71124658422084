import { createContext } from "react";

export const headerLabels = {
stageFlight : "Pre Flight",
isLightMode: false,
suitMode: "Virtual Suit",
cosmosphereMode: 'Space', 
changeDashboardView: false,
activity: "Centrifuge Training",
activitiesByFlightStage : {
  'Pre Flight': [
    'Centrifuge Training',
    'Neutral Buoyancy',
    'ISS Modules',
    'Mission Specific Modules (SpaceX Crew Dragon, Zero G)',
    'Medical observation clinic and testing facility',
    'Mock Planet Surface Training',
    'Extreme Condition Training',
    'Simulations/Research',
    'Jet Pack Training',
    'EVA Suit Training',
    'POGO (Partial Gravity Simulation)',
    'Vacuum Chambers',
    'Parabolic Flights',
    'Mission Modules',
    'General Training'
  ],
  'In Flight': [
    'Launch',
    'Space Station',
    'Space Walk',
    'Surface Ops – Moon',
    'Surface Ops – Mars',
    'Surface Asteroids',
    'Reentry',
    'Extreme Ops – Land',
    'Extreme Ops – Water',
    'Extreme Ops - Air'

  ],
  'Post Flight': [
    'Strength Training',
    'Muscle Conditioning' ,
    'Medical Observation'
  ]
},
};



export const FrontHeaderDataContext = createContext({
 stageFlight: headerLabels.stageFlight,
 suitMode: headerLabels.suitMode,
 cosmosphereMode: headerLabels.cosmosphereMode,
 activity: headerLabels.activity,
 changeDashboardView: headerLabels.changeDashboardView,
 activitiesByFlightStage: headerLabels.activitiesByFlightStage,
 isLightMode: headerLabels.isLightMode,
  changeStageFlight: (stageFlight) => {},
  changeSuitMode: (suitMode) => {},
  changeCosmosphereMode: (cosmosphereMode) => {},
  changeActivity: (activity) => {},
  changeIsISActiveStatus: () => {},
  handleDashboards: () => {},
  handleIsLightMode: (isLightMode) => {}, 
});
