import React from 'react';
import { useState, useEffect, useContext } from 'react';
import HumanDataContext from "contexts/HumanDataContext";









// import tilesData from '../Dashboards/data/Tilesdata.json';

import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
    Row,
    Card,
    CardHeader,
    CardBody,
    Col,
    CardTitle,
    Table,
    ModalBody
} from "reactstrap";
import DialComponent from './DialComponent';
import { Link } from 'react-router-dom';
import { API_ASSET_URL } from 'front/Simulation/common/config';

function MainDashboard(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

    const messages = [
        { sender: 'MCR', content: 'How do you feel' },
        { sender: 'Suit ID', content: 'I am OK' },
        { sender: 'MCR', content: 'Have you spotted any aliens yet ??' },
        { sender: '', content: 'HELP HELP' },
    ];

    const [timeCode, setTimeCode] = useState({ hours: 0, minutes: 0, seconds: 0, frames: 0 });
    const { data } = useContext(HumanDataContext);
    const [testimonialIndex, setTestimonialIndex] = useState(0);
    const metricsDelay = 5000; // Change delay as needed
    const metricsInterval = 1; // Change number of items to show at a time

    useEffect(() => {
        const interval = setInterval(() => {
            setTestimonialIndex((prevIndex) => (prevIndex + 1) % data.metrics.length);
        }, metricsDelay);

        return () => {
            clearInterval(interval);
        };
    }, [testimonialIndex, data.metrics.length]);

    const handleBadgeClick = (index) => {
        setTestimonialIndex(index);
        clearInterval(metricsInterval);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeCode((prevTimeCode) => {
                let { hours, minutes, seconds, frames } = prevTimeCode;
                frames++;
                if (frames >= 25) {
                    frames = 0;
                    seconds++;
                    if (seconds >= 60) {
                        seconds = 0;
                        minutes++;
                        if (minutes >= 60) {
                            minutes = 0;
                            hours++;
                        }
                    }
                }
                return { hours, minutes, seconds, frames };
            });
        }, 40); // 1000 ms / 25 fps = 40 ms per frame
        return () => clearInterval(interval);
    }, []);

    const formatTime = (unit) => unit.toString().padStart(2, '0');


    const handleOpenNewTab = () => {
        const newTab = window.open();
        newTab.location.href = '/earth/'; // Opens the '/earth' route in a new tab
    };


    const isSuitEngineer = user?.user_type === 'Suit Engineer';

    const restrictedRoles = ['Astronaut', 'Astronaut Trainer','Astronaut Cadet'];
    const isRestrictedRole = restrictedRoles.includes(user?.user_type);

    return (
        <div
            // style={{ backgroundColor: '#191f24' }}
            style={{ backgroundImage: `url(${API_ASSET_URL}/assets/images/moonImage.jpg)` }}
        >
            <Container fluid>
                <Row>
                    <Col md="12" className='mr-3 mt-2'>
                        <Row className='mr-3 mt-3'>
                            <Col className='' md="4">
                                <Card className='main-dashboard'
                                    style={{
                                        backgroundImage: `url(${API_ASSET_URL}/assets/images/camera_view.gif)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover', // Ensures the image fits within the container without losing its aspect ratio
                                        backgroundPosition: 'center', // Centers the image within the container
                                        width: '100%', // Set width as needed
                                        // height: '100%', // Set height as needed
                                    }}
                                >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody>
                                            <h6 className='text-center'>CAMERA VIEW</h6>
                                            {/* <img src={`${API_ASSET_URL}/assets/dashboard/motion-gifs/run-4fps-V1.gif"`} className='simulation-image' alt="Placeholder Image" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                            <Col className='' md="4">
                                <Card className='main-dashboard'
                                    //   style={{ background: '#14181B' }}
                                    style={{
                                        backgroundImage: `url(${API_ASSET_URL}/assets/images/fleet_view.png)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        width: '100%',
                                        display: 'flex',
                                        opacity: isRestrictedRole || isSuitEngineer ? 0.5 : 1, // Reduce opacity when disabled
                                        pointerEvents: isRestrictedRole || isSuitEngineer ? 'none' : 'auto', // Disable interactions when user is a Suit Engineer
                                        cursor: isRestrictedRole || isSuitEngineer ? 'not-allowed' : 'pointer', // Change cursor to indicate non-clickable
                                    }}
                                    onClick={isRestrictedRole || isSuitEngineer ? undefined : handleOpenNewTab} // Disable onClick for Suit Engineer
                                >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody
                                        //  style={{ background: '#14181B' }}
                                        >
                                            <h6 className='text-center'>Fleet View</h6>
                                            {/* <img src={`${API_ASSET_URL}/assets/dashboard/motion-gifs/kneel-4fps-V1.gif`} className='simulation-image' alt="Placeholder Image" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                            <Col className='' md="4">
                                <Card className='main-dashboard'
                                    //   style={{ background: '#14181B' }}
                                    style={{
                                        backgroundImage: `url(${API_ASSET_URL}/assets/dashboard/motion-gifs/torso-4fps-V1.gif)`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover', // Ensures the image fits within the container without losing its aspect ratio
                                        backgroundPosition: 'center', // Centers the image within the container
                                        width: '100%', // Set width as needed
                                        // height: '100%', // Set height as needed
                                    }}
                                >
                                    <div className="inner_dashboard">
                                        <span className="corner-top-left"></span>
                                        <span className="corner-top-right"></span>
                                        <CardBody
                                        // style={{ background: '#14181B' }}
                                        >
                                            <h6 className='text-center'>ASTRONAUT POSITION SYSTEM (APS)</h6>
                                            {/* <img src={TorsoMotionGif} className='simulation-image' alt="Placeholder Image" /> */}
                                        </CardBody>
                                        <span className="corner-bottom-left"></span>
                                        <span className="corner-bottom-right"></span>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Col>


                    <Col md="12">
                        <Row className='m-2 rounded'
                            style={{ backgroundColor: 'rgba(122,120,117,0.7)', }}

                        >
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/summary">
                                            <img src={`${API_ASSET_URL}/assets/dashboard/suit_configuration.gif`}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>SUIT CONFIGURATION</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/suit-diagnostics">
                                            <img src={`${API_ASSET_URL}/assets/img/SuitConfigurationIcon.gif`} style={{ width: '40%', height: 'auto' }}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>SUIT DIAGNOSTICS</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/biomedical">
                                            <img src={`${API_ASSET_URL}/assets/img/BiomedicalIcon.gif`} style={{ width: '40%', height: 'auto' }}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>BIOMEDICAL</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/biomechanics">
                                            <img src={`${API_ASSET_URL}/assets/dashboard/biomechanics.gif`}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>BIOMECHANICS</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/coginative">
                                            <img src={`${API_ASSET_URL}//assets/images/BioMedical.jpg`}></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>COGNATIVE</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Col>
                            <Col md="2">
                                <Row>
                                    <Col md="6">
                                        <Link to="/environment">
                                            <img src={`${API_ASSET_URL}/assets/img/EnvironmentIcon.gif`} style={{ width: '40%', height: 'auto' }} ></img>
                                        </Link>
                                    </Col>
                                    <Col md="6">
                                        <h6>ENVIRONMENT</h6>
                                    </Col>
                                </Row>
                                <div className="metrics">
                                    <div className="metrics-control">
                                        {data.metrics.map((_, index) => (
                                            <span
                                                key={index}
                                                className={`metrics-badge ${testimonialIndex === index ? 'active' : ''}`}
                                                onClick={() => handleBadgeClick(index)}
                                            ></span>
                                        ))}
                                    </div>
                                    <div className="metrics-content">
                                        {data.metrics.slice(testimonialIndex, testimonialIndex + 5).map((testimonial, index) => (
                                            <div key={index} className="metrics-text metrics-animate-opacity">
                                                <Row>
                                                    <Col md="6"><p>{testimonial.metric}</p></Col>
                                                    <Col md="6"><p className="testimonial-name">{testimonial.unit}</p></Col>
                                                </Row>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <DialComponent></DialComponent>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </div>
    );
}

export default MainDashboard;