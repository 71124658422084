import React from 'react';
import { useState, useRef, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
// import { useIndexedDB } from 'react-indexed-db'
import Papa from 'papaparse'
import { Box } from 'components'
import { Modal } from 'components'
import { DropTarget } from 'react-drag-drop-container'
import { EMOTIBIT_BIOMETRIC_TYPETAG, EMOTIBIT_GENERAL_NETWORKING_TYPETAG } from './emotibit/constants'

import { accordionData, results } from './Resources'
import SaveAsModal from './SaveAsModal';
import MetricListPanel from './MetricListPanel';
import SingleNumberTile from './SingleNumberTile';
import ImageTile from './ImageTile';
// import { SingleNumberTile, ImageTile, SaveAsModal, MetricListPanel } from '.'
// import './SummaryPage.scss'
import 'assets/css/SessionSummary.scss';
import Header from 'front/Header/Header';
import SciChartExample from 'front/SciCharts/SciChartExample';
import SciChartsExampleOne from 'front/SciCharts/SciChartsExampleOne';
import SciChartExampleThree from 'front/SciCharts/SciChartExampleThree';
import SciChartsExampleFour from 'front/SciCharts/SciChartsExampleFour';
import SciChartExampleFive from 'front/SciCharts/SciChartsExampleFive';
import SciChartsExampleTwo from 'front/SciCharts/SciChartsExampleTwo';
import Footer from 'front/Footer/Footer';
import { FrontHeaderDataContext } from 'contexts/FrontHeaderDataContext';
import { API_ASSET_URL } from 'front/Simulation/common/config';
import ThemeRiverChart from '../SuitDiagnostics/Metric_Graphs/Echart_Library/Parllel';
import HeatmapChart from '../SuitDiagnostics/Metric_Graphs/Echart_Library/HeatmapChart';
import LineChart from '../SuitDiagnostics/Metric_Graphs/Echart_Library/LineChart';
import DynamicAreaChart from '../SuitDiagnostics/Metric_Graphs/Echart_Library/AreaChart';
import SurfaceChart from '../SuitDiagnostics/Metric_Graphs/Echart_Library/SurfaceChart';
import Bar3DChart from '../SuitDiagnostics/Metric_Graphs/Echart_Library/Bar3DChart';

  
   

const getMetricDetails = (item, resultsData) => {
  const foundResults = resultsData?.find((x) => x.id === item.id)
  if (foundResults) {
    return foundResults
  } else {
    return { name: item.name, unit: '', value: 'Result not Found' }
  }
}

const EmptyTile = ({ onDrop }) => {
  return (
    <DropTarget targetKey="metric" onHit={(e) => onDrop(e)}>
      <div className="add-icon">
        <img src={`${API_ASSET_URL}/assets/images/cross.svg`} />
      </div>
    </DropTarget>
  )
}

const TileWithData = ({ item, resultsData }) => {
  const metricDetail = getMetricDetails(item, resultsData)
  if (metricDetail.type === 'Single') {
    return <SingleNumberTile metric={metricDetail}></SingleNumberTile>
  } else if (metricDetail.type === 'Image') {
    return <ImageTile metric={metricDetail} XAxisMetric={`Output(g's)`} YAxisMetric={'Time(sec)'}></ImageTile>
  } else {
    return <label className="empty-metric-data">Metric not collected</label>
  }
}

const MetricTile = ({ resultsData, setMetricStack, setMetricTiles, metricTiles, item, position, metricStack }) => {
  const navigate = useNavigate()
  const [isLightModeOne, setIsLightModeOne] = useState(false);
  const [isLightModeTwo, setIsLightModeTwo] = useState(false);
  const [isLightModeThree, setIsLightModeThree] = useState(false);
  const [isLightModeFour, setIsLightModeFour] = useState(false);
  const [isLightModeFive, setIsLightModeFive] = useState(false);
  const [isLightModeSix, setIsLightModeSix] = useState(false);
  const [values, setValues] = useState([]);
  const updateMetricStack = (updatedData) => {
    const newArray = updatedData.filter((x) => isNaN(x))
    setMetricStack(newArray)
  }

  const handleToggle = (index) => {

    switch (index) {
      case 0:
        setIsLightModeOne((prevMode) => !prevMode)
        break;
      case 1:
        setIsLightModeTwo((prevMode) => !prevMode)
        break;
      case 2:
        setIsLightModeThree((prevMode) => !prevMode)
        break;
      case 3:
        setIsLightModeFour((prevMode) => !prevMode)
        break;
      case 4: 
        setIsLightModeFive((prevMode) => !prevMode)
        break;
      case 5:
        setIsLightModeSix((prevMode) => !prevMode)
        break;
      default:
        break;
    }
  };

  const onDrop = (e) => {
    const item = e.dragData
    const isStackExist = metricStack.findIndex((x) => x.id === item.id) !== -1
    const updatedData = [...metricTiles]
    if (!isStackExist) {
      updatedData[position] = item
      updateMetricStack(updatedData)
    } else {
      const existingMetricIndex = updatedData.findIndex((x) => isNaN(x) && x?.id === item.id)
      updatedData[existingMetricIndex] = existingMetricIndex
      updatedData[position] = item
      updateMetricStack(updatedData)
    }
    setMetricTiles(updatedData)
  }

  const handleClick = () => {
    
    const tag = item.typeTag || ''
    if (tag) {
      navigate('/emotibit',{state: { value: tag }})
    }
  }

  

  if (!isNaN(item)) {
    return <EmptyTile onDrop={onDrop} />
  } else {
    return (
      <DropTarget
        targetKey="metric"
        dropData={item}
        onHit={(e) => onDrop(e)}
        key={position}
        onDragLeave={() => console.clear()}>
        <div className="metric-tile-container" 
            //  onClick={handleClick}
            // onClick={() => changeToSciCharts(item)}
          >
          <div className="metric-header">
            <label className="metric-name text-white">{item.name}</label>
            <label class="switch">
            <input type="checkbox" onChange={() => handleToggle(position)} />
            <span class="slider round"></span>
            </label>
            {item.icon && <img src={item.icon} className="metric-icon" />}
            
          </div>
          {/* {isLightModeOne ? <SciChartExample /> : (position === 0 ? <TileWithData item={item} resultsData={resultsData} /> : '')}
          {isLightModeTwo   ? <SciChartsExampleOne /> : (position === 1 ? <TileWithData item={item} resultsData={resultsData} /> : '' )}
          {isLightModeThree   ? <SciChartsExampleTwo /> : (position === 2 ? <TileWithData item={item} resultsData={resultsData} /> : '' )}
          {isLightModeFour ? <SciChartExampleThree /> : (position === 3 ? <TileWithData item={item} resultsData={resultsData} /> : '' )}
          {isLightModeFive  ? <SciChartsExampleFour /> : (position === 4 ?  <TileWithData item={item} resultsData={resultsData} /> : '' )}
          {isLightModeSix  ? <SciChartExampleFive /> : (position === 5 ? <TileWithData item={item} resultsData={resultsData} /> : '' )}
           */}

{isLightModeOne ? <ThemeRiverChart /> : (position === 0 ? <TileWithData item={item} resultsData={resultsData} /> : '')}
          {isLightModeTwo   ? <HeatmapChart /> : (position === 1 ? <TileWithData item={item} resultsData={resultsData} /> : '' )}
          {isLightModeThree   ? <LineChart /> : (position === 2 ? <TileWithData item={item} resultsData={resultsData} /> : '' )}
          {isLightModeFour ? <DynamicAreaChart /> : (position === 3 ? <TileWithData item={item} resultsData={resultsData} /> : '' )}
          {isLightModeFive  ? <SurfaceChart /> : (position === 4 ?  <TileWithData item={item} resultsData={resultsData} /> : '' )}
          {isLightModeSix  ? <Bar3DChart /> : (position === 5 ? <TileWithData item={item} resultsData={resultsData} /> : '' )}
          
          
        </div>
      </DropTarget>
    )
  }
}
function SessionSummary(props) {
  const location = useLocation();
  const modal = useRef(null)
  // const { getAll, add, update, deleteRecord } = useIndexedDB('emotibit')
  const [metricStack, setMetricStack] = useState([])
  const [metricTiles, setMetricTiles] = useState(Array.from({ length: 6 }, (v, i) => i))
  const [updatedAccordionData, setUpdatedAccordionData] = useState(accordionData)
  const [updatedResultsData, setUpdatedResultsData] = useState(results)
  const [dbValues, setDbValues] = useState([])

  useEffect(() => {
    // getAll().then((personsFromDB) => {
    //   setDbValues((prev) => [...prev, ...personsFromDB])
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSelectedChild = (data) => {
    const selectedItems = data.filter((x) => {
      const isItemSelected = metricStack.findIndex((metric) => metric?.id === (x.id || x)) !== -1
      if (isItemSelected) return true
      else if (!isItemSelected && x.children) {
        return getSelectedChild(x.children).length
      }
    })
    return selectedItems
  }

  const onFileLoad = (e) => {
    const files = e.target.files
    if (files.length) {
      Papa.parse(files[0], {
        header: true,
        complete: async function (finalResults) {
          const data = finalResults.data || []
          const key = data[0].TypeTag || ''
          if (!key) {
            return
          }
          const existingTagValue = dbValues?.find((v) => v.typeTag === key)
          if (existingTagValue) {
            // await deleteRecord(existingTagValue.id)
            // await update({ typeTag: key, data})
          } else {
            // await add({ typeTag: key, data })
          }
          const updatedDbValues = await ''
          setUpdatedAccordionData(accordionData)
          setUpdatedResultsData(results)
          calculateAverageTypeTagValue(updatedDbValues)
        },
      })
    }
  }

  const getContentName = (typeTag) => {
    return (
      [...EMOTIBIT_BIOMETRIC_TYPETAG, ...EMOTIBIT_GENERAL_NETWORKING_TYPETAG].find((v) => v.value === typeTag)?.label ||
      typeTag
    )
  }

  const averageTypeTagValue = useCallback((selectedEmotiBitData) => {
    let average = null
    if (selectedEmotiBitData?.length) {
      const sumOfTypeTagValue = selectedEmotiBitData
        .filter((v) => v[v.TypeTag])
        .map((data) => +data[data.TypeTag])
        .filter(Boolean)

      average = (sumOfTypeTagValue.reduce((a, b) => a + b, 0) / sumOfTypeTagValue.length).toFixed(2) || null

      setUpdatedAccordionData((prevAccordion) => {
        let updatedData = prevAccordion?.find((v) => v.id === 7)

        updatedData = {
          ...updatedData,
          content: [
            ...updatedData.content,
            {
              id: +`7${updatedData.content.length === 0 ? 1 : updatedData.content.length + 1}`,
              name: getContentName(selectedEmotiBitData[0].TypeTag),
              typeTag: selectedEmotiBitData[0].TypeTag,
            },
          ],
        }
        return prevAccordion.map((v) => {
          if (v.id === 7) {
            return {...v,...updatedData}
          }
          return v
        })
      })

      setUpdatedResultsData((prevResult) => {
        const updatedData = prevResult?.find((v) => v.id === 71)
        const result = {
          name: getContentName(selectedEmotiBitData[0].TypeTag),
          value: average,
          type: 'Single',
          unit: selectedEmotiBitData[0].TypeTag,
          typeTag: selectedEmotiBitData[0].TypeTag,
        }
        if (!updatedData.name) {
          return prevResult.map((v) => {
            if (v.id === 71) {
              return { ...v, ...result, id: 71 }
            }
            return v
          })
        } else {
          const newResult = [...prevResult, { ...result, id: prevResult[prevResult.length - 1].id + 1 }]
          return newResult
        }
      })
    }

    return average
  }, [])

  const calculateAverageTypeTagValue = useCallback(
    (emotibitData = []) => {
        emotibitData.forEach((v) => {
          averageTypeTagValue(v.data)
        })
    },
    [averageTypeTagValue]
  )

  useEffect(() => {
    if (dbValues?.length) {
      calculateAverageTypeTagValue(dbValues)
    }
  }, [calculateAverageTypeTagValue, dbValues])

  return (
    <div className='app' style={{height:'120vh'}}>
      <Header  currentRoute={location.pathname}></Header>
      <div className="summary-page">
      <Box className="left-panel">
        <label>Choose Session Metrics</label>
        <MetricListPanel
          accordionData={updatedAccordionData}
          getSelectedChild={getSelectedChild}
          setMetricStack={setMetricStack}
          metricStack={metricStack}
          setMetricTiles={setMetricTiles}
          metricTiles={metricTiles}
        />
        <div className="save-button-container">
          <label style={{ cursor: 'pointer' }}>
            Load File
            {/* <input className="load-emotibit-btn" type="file" accept=".csv" onChange={onFileLoad} /> */}
          </label>
        </div>
        {metricStack.length ? (
          <div className="save-button-container" disabled={true}>
            <button 
            // onClick={() => modal.current.open()}
            >save session</button>
          </div>
        ) : null}
      </Box>
      <div className="tile-panel">
        {metricTiles.map((item, index) => (
          <MetricTile
            resultsData={updatedResultsData}
            metricTiles={metricTiles}
            setMetricStack={setMetricStack}
            setMetricTiles={setMetricTiles}
            metricStack={metricStack}
            key={index}
            item={item}
            position={index}
          />
        ))}
      </div>
      <Modal ref={modal} title="save modal">
        <SaveAsModal handleClose={() => modal.current.close()} metricStack={metricStack} />
      </Modal>
    </div>
    <Footer></Footer>
    </div>
    
  )
}

export default SessionSummary;